<template>
  <sdPageHeader :title="title"> </sdPageHeader>
  <Main>
    <HorizontalFormStyleWrap>
      <sdCards headless>
        <a-form
          layout="horizontal"
          :model="formState"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          :rules="rules"
          @finish="handleSubmit"
        >
          <a-form-item name="month_year" label="Bulan">
            <a-month-picker
              v-model:value="formState.month_year"
              placeholder="Pilih Bulan"
              format="MM-YYYY"
              valueFormat="YYYY-MM"
              :disabled="mode == 'Ubah' ? true : false"
            />
          </a-form-item>
          <a-form-item name="target" label="Target">
            <a-input-number v-model:value="formState.target" placeholder="Masukkan Target Per Bulan" />
          </a-form-item>
          <a-form-item name="jumlah" label="Jumlah">
            <a-input-number v-model:value="formState.pelaksanaans.length" placeholder="Jumlah" disabled />
          </a-form-item>
          <a-row>
            <a-col
              style="margin-bottom:12px;"
              :lg="{ span: 20, offset: 4 }"
              :md="{ span: 15, offset: 9 }"
              :xs="{ span: 24, offset: 0 }"
            >
              <h3>Detail Kegiatan Pelaksanaan</h3>
            </a-col>
          </a-row>
          <a-row>
            <a-col v-for="(pelaksanaan, index) in formState.pelaksanaans" :key="index" :span="24">
              <a-form-item name="tanggal" label="Tanggal Pelasanaan">
                <a-date-picker
                  v-model:value="pelaksanaan.tanggal_pelaksanaan"
                  placeholder="Pilih Tanggal"
                  format="DD-MM-YYYY"
                  valueFormat="YYYY-MM-DD"
                  style="width: 75%;"
                />
                <a-button type="dashed" size="large" style="margin-left:8px;" @click="hapusItemPelaksanaan(index)">
                  Hapus Kegiatan Pelaksanaan
                </a-button>
              </a-form-item>

              <a-form-item name="judul" label="Judul">
                <a-input v-model:value="pelaksanaan.judul" placeholder="Masukkan judul" />
              </a-form-item>

              <a-form-item name="pemateri" label="Pemateri">
                <a-input v-model:value="pelaksanaan.pemateri" placeholder="Masukkan pemateri" />
              </a-form-item>

              <!-- <a-form-item name="file_materi" label="Materi">
                <a-upload :file-list="pelaksanaan.file_materi">
                  <a-button size="large"> <a-icon type="upload" /> Select File Materi </a-button>
                </a-upload>
              </a-form-item> -->

              <a-form-item name="file_materi" label="File Materi">
                <a-upload
                  :file-list="pelaksanaan.file_materi"
                  :remove="handleRemoveMateri"
                  :before-upload="beforeUploadMateri"
                  :onChange="onChangeFile(index)"
                >
                  <a-button> <a-icon type="upload" /> Select File </a-button>
                </a-upload>
              </a-form-item>

              <!-- <a-form-item name="file_foto" label="Foto Pelaksanaan">
                <a-upload :file-list="pelaksanaan.file_foto">
                  <a-button size="large"> <a-icon type="upload" /> Select File Foto </a-button>
                </a-upload>
              </a-form-item> -->

              <a-form-item name="file_foto" label="Foto Pelaksanaan">
                <a-upload
                  :file-list="pelaksanaan.file_foto"
                  :remove="handleRemovePelaksanaan"
                  :before-upload="beforeUploadPelaksanaan"
                  :onChange="onChangeFile(index)"
                >
                  <a-button> <a-icon type="upload" /> Select File </a-button>
                </a-upload>
              </a-form-item>

              <!-- <a-form-item name="file_sumber_materi" label="Sumber Materi">
                <a-upload :file-list="pelaksanaan.file_sumber_materi">
                  <a-button size="large"> <a-icon type="upload" /> Select File Foto </a-button>
                </a-upload>
              </a-form-item> -->

              <a-form-item name="file_sumber_materi" label="Sumber Materi">
                <a-upload
                  :file-list="pelaksanaan.file_sumber_materi"
                  :remove="handleRemoveSumber"
                  :before-upload="beforeUploadSumber"
                  :onChange="onChangeFile(index)"
                >
                  <a-button> <a-icon type="upload" /> Select File </a-button>
                </a-upload>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :lg="{ span: 20, offset: 4 }" :md="{ span: 15, offset: 9 }" :xs="{ span: 24, offset: 0 }">
              <a-form-item>
                <a-button type="dashed" style="width: 36%" size="large" @click="tambahItemPelaksanaan">
                  Tambah Kegiatan Pelaksanaan
                </a-button>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row style="margin-bottonm:12px;">
            <a-col :lg="{ span: 20, offset: 4 }" :md="{ span: 15, offset: 9 }" :xs="{ span: 24, offset: 0 }">
              <div class="sDash_form-action">
                <sdButton class="sDash_form-action__btn" type="primary" size="large" htmlType="submit">
                  {{ isLoading ? 'Harap Menunggu...' : mode }}
                </sdButton>

                <router-link to="/laporan-manajemen/sharing-knowledge">
                  <sdButton class="sDash_form-action__btn" type="light" size="large" htmlType="button">
                    Batal
                  </sdButton>
                </router-link>
              </div>
            </a-col>
          </a-row>
        </a-form>
      </sdCards>
    </HorizontalFormStyleWrap>
  </Main>
</template>

<script>
import { HorizontalFormStyleWrap } from '../../../components/crud/style';
import { Main } from '../../styled';
import { useStore } from 'vuex';
import { reactive, computed, onMounted, toRaw } from 'vue';
import { useRoute } from 'vue-router';

const VariableKpiDivisiForm = {
  name: 'VariableKpiDivisiForm',
  components: { HorizontalFormStyleWrap, Main },
  data() {
    return {
      title: this.mode + ' ' + 'Kegiatan Sharing Knowledge',
    };
  },
  props: ['mode', 'module'],
  setup(props) {
    const { state, dispatch } = useStore();
    const { params } = useRoute();
    const isLoading = computed(() => state.crud.loading);
    const crud = computed(() => state.crud.data);

    class Pelaksanaan {
      tanggal_pelaksanaan;
      judul;
      pemateri;
      file_materi = [];
      file_foto = [];
      file_sumber_materi = [];
    }

    const formState = reactive({
      id: undefined,
      month_year: '',
      target: '',
      pelaksanaans: [],
      materiFiles: [],
    });

    const labelCol = {
      lg: 4,
      md: 9,
      xs: 24,
    };

    const wrapperCol = {
      lg: 20,
      md: 15,
      xs: 24,
    };

    const handleSubmit = () => {
      // const formData = new FormData();

      let dataParams = toRaw(formState);
      let my = dataParams.month_year.split('-');
      dataParams['month'] = my[1];
      dataParams['year'] = my[0];

      // console.log(dataParams);

      // Object.keys(values).forEach(key => {
      //   if (key != 'pelaksanaans') formData.append(key, values[key]);
      // });

      // formState.fileList.forEach(file => {
      //   if (!file.old) {
      //     formData.append('files[]', file);
      //   } else {
      //     formData.append('oldFiles[]', file.name);
      //   }
      // });

      if (props.mode == 'Tambah') {
        dispatch('axiosCrudSubmitData', {
          url: 'laporan-sharing-knowledge',
          data: dataParams,
        });
      } else if (props.mode == 'Ubah') {
        dispatch('axiosDataUpdate', {
          id: params.itemId,
          url: 'laporan-sharing-knowledge',
          data: dataParams,
        });
      }
    };

    // hitung gap
    const tambahItemPelaksanaan = () => {
      formState.pelaksanaans.push(new Pelaksanaan());
    };

    const hapusItemPelaksanaan = index => {
      formState.pelaksanaans.splice(index, 1);
    };

    let inputStringRule = [
      {
        required: true,
        message: 'Harap Diisi',
        trigger: 'blur',
      },
    ];
    const rules = {
      month_year: inputStringRule,
      target: [
        {
          type: 'number',
          required: true,
          min: 1,
          message: 'Harap Diisi, Minimum 1 Kegiatan',
          trigger: 'blur',
        },
      ],
      tanggal_pelaksanaan: [
        {
          type: 'object',
          required: true,
          message: 'Harap Diisi',
          trigger: 'blur',
        },
      ],
    };

    onMounted(() => {
      if (props.mode == 'Ubah') {
        const data = {
          url: 'laporan-sharing-knowledge',
          id: params.itemId,
        };

        dispatch('axiosSingleDataGet', data).then(() => {
          formState.id = crud.value.id;
          formState.month_year = crud.value.year + '-' + crud.value.month;
          formState.target = crud.value.target;
          formState.pelaksanaans = crud.value.pelaksanaans;
        });
      }
    });

    const onChangeFile = index => {
      formState.activeIndex = index;
    };

    const beforeUploadMateri = file => {
      console.log(file);
      formState.pelaksanaans[formState.activeIndex].file_materi = [
        ...formState.pelaksanaans[formState.activeIndex].file_materi,
        file,
      ];
      return false;
    };

    const handleRemoveMateri = file => {
      const index = formState.pelaksanaans[formState.activeIndex].file_materi.indexOf(file);
      const newMateriFiles = formState.pelaksanaans[formState.activeIndex].file_materi.slice();
      newMateriFiles.splice(index, 1);
      formState.pelaksanaans[formState.activeIndex].file_materi = newMateriFiles;
    };

    const beforeUploadPelaksanaan = file => {
      console.log(file);
      formState.pelaksanaans[formState.activeIndex].file_foto = [
        ...formState.pelaksanaans[formState.activeIndex].file_foto,
        file,
      ];
      return false;
    };

    const handleRemovePelaksanaan = file => {
      const index = formState.pelaksanaans[formState.activeIndex].file_foto.indexOf(file);
      const newPelaksanaanFiles = formState.pelaksanaans[formState.activeIndex].file_foto.slice();
      newPelaksanaanFiles.splice(index, 1);
      formState.pelaksanaans[formState.activeIndex].file_foto = newPelaksanaanFiles;
    };

    const beforeUploadSumber = file => {
      console.log(file);
      formState.pelaksanaans[formState.activeIndex].file_sumber_materi = [
        ...formState.pelaksanaans[formState.activeIndex].file_sumber_materi,
        file,
      ];
      return false;
    };

    const handleRemoveSumber = file => {
      const index = formState.pelaksanaans[formState.activeIndex].file_sumber_materi.indexOf(file);
      const newSumberFiles = formState.pelaksanaans[formState.activeIndex].file_sumber_materi.slice();
      newSumberFiles.splice(index, 1);
      formState.pelaksanaans[formState.activeIndex].file_sumber_materi = newSumberFiles;
    };

    return {
      formState,
      labelCol,
      wrapperCol,
      handleSubmit,
      isLoading,
      rules,
      tambahItemPelaksanaan,
      hapusItemPelaksanaan,
      onChangeFile,
      beforeUploadMateri,
      handleRemoveMateri,
      beforeUploadPelaksanaan,
      handleRemovePelaksanaan,
      beforeUploadSumber,
      handleRemoveSumber,
    };
  },
};

export default VariableKpiDivisiForm;
</script>
